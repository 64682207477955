import { z } from "zod";

import type { Brand } from "../framework/brand.ts";
import { ValueObject } from "../framework/value-object.ts";
import { serializeFrom } from "../utils/serialization.ts";
import { Currency } from "./currency.ts";

export type MonetaryValueOptions = {
  locale?: string;
};

export type MonetaryValueProps = Brand<
  {
    value: number;
    currency: Currency;
    options?: MonetaryValueOptions;
  },
  "MonetaryValue"
>;

export type MonetaryValueFormatOptions = {
  locale?: string;
};

/**
 * MonetaryValue is a ValueObject that represents a monetary value in the
 * currency minor unit (eg: Cents for Dollar).
 * @extends ValueObject<MonetaryValueProps>
 */
export class MonetaryValue extends ValueObject<MonetaryValueProps> {
  private readonly _locale: string;
  private readonly _intlFormatter: Intl.NumberFormat;

  get value(): number {
    return this.props.value;
  }

  get locale(): string {
    return this._locale;
  }

  get intlFormatter(): Intl.NumberFormat {
    return this._intlFormatter;
  }

  get currency(): Currency {
    return this.props.currency;
  }

  public withLocale(locale: string): MonetaryValue {
    return new MonetaryValue({
      ...this.props,
      options: { ...this.props.options, locale },
    });
  }

  private constructor(props: MonetaryValueProps) {
    super(props);
    this._locale = props.options?.locale || "en";
    this._intlFormatter = new Intl.NumberFormat(this._locale, {
      style: "currency",
      currency: this.props.currency.isoCode,
      compactDisplay: "short",
    });
  }

  public toString(): string {
    const { currency } = this.props;
    const value = this.props.value / (currency.minorUnitToUnit || 1);
    return this.intlFormatter.format(value);
  }

  public toJSON() {
    return serializeFrom({
      value: this.value,
      currency: this.currency.toJSON(),
      display: this.toString(),
    });
  }

  public add(monetaryValue: MonetaryValue): MonetaryValue {
    if (!this.currency.equals(monetaryValue.currency)) {
      throw new Error(
        `Currency must be the same to add monetary values. ${this.currency.isoCode} is different than ${monetaryValue.currency.isoCode}`,
      );
    }
    return MonetaryValue.create(
      this.value + monetaryValue.value,
      this.currency.isoCode,
      this.props.options,
    );
  }

  public subtract(monetaryValue: MonetaryValue): MonetaryValue {
    if (!this.currency.equals(monetaryValue.currency)) {
      throw new Error(
        `Currency must be the same to add monetary values. ${this.currency.isoCode} is different than ${monetaryValue.currency.isoCode}`,
      );
    }
    return MonetaryValue.create(
      this.value - monetaryValue.value,
      this.currency.isoCode,
      this.props.options,
    );
  }

  public multiply(factor: number): MonetaryValue {
    return MonetaryValue.create(
      Math.floor(this.value * factor),
      this.currency.isoCode,
      this.props.options,
    );
  }

  public reduceByPercent(percent: number): MonetaryValue {
    const value = this.value - this.value * (percent / 100);
    return MonetaryValue.create(
      Math.floor(value),
      this.currency.isoCode,
      this.props.options,
    );
  }

  public addByPercent(percent: number): MonetaryValue {
    const value = this.value + this.value * (percent / 100);
    return MonetaryValue.create(
      Math.floor(value),
      this.currency.isoCode,
      this.props.options,
    );
  }

  public divide(factor: number): MonetaryValue {
    return MonetaryValue.create(
      Math.floor(this.value / factor),
      this.currency.isoCode,
      this.props.options,
    );
  }

  public equals(monetaryValue: MonetaryValue): boolean {
    return (
      this.value === monetaryValue.value &&
      this.currency.equals(monetaryValue.currency)
    );
  }

  public static fromString(str: string): MonetaryValue {
    return parseMoney(str);
  }

  public static create(
    monetaryValue: number,
    currencyCode = "EUR",
    options: MonetaryValueOptions = {},
  ): MonetaryValue {
    const validateValue = z.number().int().safeParse(monetaryValue);
    if (!validateValue.success) {
      throw new Error(
        `MonetaryValue should be an integer and must be 0 or greater. Input value was ${monetaryValue}`,
      );
    }

    return new MonetaryValue({
      value: validateValue.data,
      currency: Currency.create(currencyCode),
      options,
    } as MonetaryValueProps);
  }
}

export const symbols: {
  [currency: string]: string[];
} = {
  BRL: ["R$", "BRL"],
  RON: ["lei", "LEI", "Lei", "RON"],
  USD: ["$", "US$", "US dollars", "USD"],
  GBP: ["£", "GBP"],
  EUR: ["€", "Euro", "EUR"],
  RUB: ["руб", "RUB"],
  ILS: ["₪", "ILS"],
  INR: ["Rs.", "Rs", "INR", "RS", "RS."],
  PHP: ["₱", "PHP", "PhP", "Php"],
  JPY: ["¥", "JPY", "円"],
  AUD: ["A$", "AU$", "AUD"],
  CAD: ["CA$", "C$", "CAD"],
};

export type Money = {
  amount: number;
  currency: ParsedCurrency | null;
};

export type ParsedCurrency =
  | "USD"
  | "GBP"
  | "EUR"
  | "BRL"
  | "RUB"
  | "ILS"
  | "RON";

const getCurrencySymbolAndIndex = (
  text: string,
): { index: number; currency: Currency | null } => {
  let index = 0;
  let currency: Currency | null = null;
  for (const [currency, lookupSymbols] of Object.entries(symbols)) {
    for (const symbol of lookupSymbols) {
      const index = text.indexOf(symbol);
      if (index > -1) {
        //found symbol
        return {
          index,
          currency: Currency.create(currency),
        };
      }
    }
  }
  return { index, currency };
};

export const extractNumberFromMoneyString = (
  text: string,
  index: number,
): number => {
  //search numbers near the currency
  const start = Math.max(0, index - 40);
  const end = index + 40;
  let slice = text.substr(start, end);

  //remove text
  slice = slice.replace(/[^\d|^.|^,]/g, "");
  //remove any trailing dots and commas
  slice = slice.replace(/(,|\.)*$/, "");
  //remove any dot and comma from the front
  while (slice.charAt(0) === "." || slice.charAt(0) === ",") {
    slice = slice.substr(1);
  }

  if (!slice.length) {
    throw new Error("No number found");
  }

  let dotCount = slice.split(".").length - 1;
  let commaCount = slice.split(",").length - 1;

  let amount = 0;
  if (dotCount === 0 && commaCount === 0) {
    //integer
  } else if (commaCount > 1 && dotCount <= 1) {
    //comma are delimiters
    //dot is decimal separator
    slice = slice.split(",").join("");
  } else if (dotCount > 1 && commaCount <= 1) {
    //comma are delimiters
    //dot is decimal separator
    slice = slice.split(".").join("");
    slice = slice.split(",").join(".");
  } else if (dotCount > 0 && commaCount > 0) {
    //check position
    if (slice.indexOf(".") > slice.indexOf(",")) {
      //215,254.23
      slice = slice.split(",").join("");
    } else {
      //215.2123,23
      slice = slice.split(".").join("");
      slice = slice.split(",").join(".");
    }
  } else if (dotCount === 1 && commaCount === 0) {
    //check groups
    //215.21 is 215.21
    //215.212 is 215222
    //215.1 is 215.1
    const segments = slice.split(".");
    const second = segments[1];
    if (second && second.length === 3) {
      //group separator
      slice = slice.replace(".", "");
    }
  } else {
    // (commaCount === 1 && dotCount === 0)

    //check groups
    //215,21 is 215.21
    //215,212 is 215222
    //215,1 is 215.1
    const segments = slice.split(",");
    const second = segments[1];
    if (second && second.length === 3) {
      //group separator
      slice = slice.replace(",", "");
    } else {
      //decimal separator
      slice = slice.replace(",", ".");
    }
  }

  amount = parseFloat(slice);
  return amount;
};

export const parseMoney = (text: string) => {
  let { index, currency } = getCurrencySymbolAndIndex(text);
  if (!currency) {
    throw new Error("Currency not found");
  }

  const amount = extractNumberFromMoneyString(text, index);

  return MonetaryValue.create(
    amount * currency.minorUnitToUnit,
    currency.isoCode,
  );
};

export const formatCurrency = (
  amount: number | null,
  currency: Currency | string,
  locales?: string[] | string,
) => {
  if (typeof currency === "string") {
    currency = Currency.create(currency);
  }
  return new Intl.NumberFormat(locales ?? "en-US", {
    style: "currency",
    currency: currency.isoCode,
    minimumFractionDigits: currency.minimumFractionDigits,
  }).format((amount || 0) / currency.minorUnitToUnit);
};
