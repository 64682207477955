import { z } from "zod";

import { ValueObject } from "../framework/value-object.ts";
import { CURRENCIES } from "../libs/currencies.ts";
import { serializeFrom } from "../utils/serialization.ts";

function isCurrency(
  currencyCode: string,
): currencyCode is keyof typeof CURRENCIES {
  return currencyCode in CURRENCIES;
}

export const CurrencySchema = z
  .string()
  .min(1)
  .transform((val, ctx) => {
    if (!isCurrency(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Not a correct currency code",
      });
      return z.NEVER;
    }
    return CURRENCIES[val];
  })
  .brand("Currency");

export type CurrencyProps = z.infer<typeof CurrencySchema>;

/**
 * Currency is a ValueObject that represents a Currency according to iso4217.
 * @extends ValueObject<CurrencyProps>
 */
export class Currency extends ValueObject<CurrencyProps> {
  get isoCode() {
    return this.props.isoCode;
  }
  get name() {
    return this.props.name;
  }
  get symbol() {
    return this.props.symbol;
  }
  get alternateSymbols() {
    return this.props.alternateSymbols;
  }
  get minorUnit() {
    return this.props.minorUnit;
  }
  get minorUnitToUnit() {
    return this.props.minorUnitToUnit;
  }
  get symbolFirst() {
    return this.props.symbolFirst;
  }
  get htmlEntity() {
    return this.props.htmlEntity;
  }
  get decimalMark() {
    return this.props.decimalMark;
  }
  get thousandsSeparator() {
    return this.props.thousandsSeparator;
  }
  get isoNumeric() {
    return this.props.isoNumeric;
  }

  get minimumFractionDigits() {
    return this.props.minimumFractionDigits;
  }

  public equals(other: Currency): boolean {
    return this.isoCode === other.isoCode;
  }

  public static create(currencyCode: string): Currency {
    const validate = CurrencySchema.safeParse(currencyCode);
    if (!validate.success) {
      throw new Error(validate.error.format()._errors.join(", "));
    }
    return new Currency(validate.data);
  }

  public toJSON() {
    return serializeFrom(this.props);
  }
}
