export interface ICurrency {
  isoCode: string;
  name: string;
  symbol: string | null;
  alternateSymbols: string[];
  minorUnit: string | null;
  minorUnitToUnit: number;
  symbolFirst: boolean;
  htmlEntity: string;
  decimalMark: string;
  thousandsSeparator: string;
  isoNumeric: string;
  minimumFractionDigits: number;
}

export interface ICurrencies {
  [key: string]: ICurrency;
}

export const CURRENCIES = {
  EUR: {
    isoCode: "EUR",
    name: "Euro",
    symbol: "€",
    alternateSymbols: [],
    minorUnit: "Cent",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "&#x20AC;",
    decimalMark: ",",
    thousandsSeparator: ".",
    isoNumeric: "978",
    minimumFractionDigits: 2,
  },
  USD: {
    isoCode: "USD",
    name: "United States Dollar",
    symbol: "$",
    alternateSymbols: ["US$"],
    minorUnit: "Cent",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "$",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "840",
    minimumFractionDigits: 2,
  },
  GBP: {
    isoCode: "GBP",
    name: "British Pound",
    symbol: "£",
    alternateSymbols: [],
    minorUnit: "Penny",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "&#x00A3;",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "826",
    minimumFractionDigits: 2,
  },
  AUD: {
    isoCode: "AUD",
    name: "Australian Dollar",
    symbol: "$",
    alternateSymbols: ["A$"],
    minorUnit: "Cent",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "$",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "036",
    minimumFractionDigits: 2,
  },
  CAD: {
    isoCode: "CAD",
    name: "Canadian Dollar",
    symbol: "$",
    alternateSymbols: ["C$", "CAD$"],
    minorUnit: "Cent",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "$",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "124",
    minimumFractionDigits: 2,
  },
  CNY: {
    isoCode: "CNY",
    name: "Chinese Renminbi Yuan",
    symbol: "¥",
    alternateSymbols: ["CN¥", "元", "CN元"],
    minorUnit: "Fen",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "&#x5713;",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "156",
    minimumFractionDigits: 2,
  },
  HKD: {
    isoCode: "HKD",
    name: "Hong Kong Dollar",
    symbol: "$",
    alternateSymbols: ["HK$"],
    minorUnit: "Cent",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "$",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "344",
    minimumFractionDigits: 2,
  },
  JPY: {
    isoCode: "JPY",
    name: "Japanese Yen",
    symbol: "¥",
    alternateSymbols: ["円", "圓"],
    minorUnit: null,
    minorUnitToUnit: 1,
    symbolFirst: true,
    htmlEntity: "&#x00A5;",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "392",
    minimumFractionDigits: 0,
  },
  NZD: {
    isoCode: "NZD",
    name: "New Zealand Dollar",
    symbol: "$",
    alternateSymbols: ["NZ$"],
    minorUnit: "Cent",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "$",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "554",
    minimumFractionDigits: 2,
  },
  CHF: {
    isoCode: "CHF",
    name: "Swiss Franc",
    symbol: "Fr",
    alternateSymbols: ["SFr", "CHF"],
    minorUnit: "Rappen",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "756",
    minimumFractionDigits: 2,
  },
  PLN: {
    isoCode: "PLN",
    name: "Polish Złoty",
    symbol: "zł",
    alternateSymbols: [],
    minorUnit: "Grosz",
    minorUnitToUnit: 100,
    symbolFirst: false,
    htmlEntity: "",
    decimalMark: ",",
    thousandsSeparator: " ",
    isoNumeric: "985",
    minimumFractionDigits: 2,
  },
  INR: {
    isoCode: "INR",
    name: "Indian Rupee",
    symbol: "₹",
    alternateSymbols: ["Rs", "৳", "૱", "௹", "रु", "₨"],
    minorUnit: "Paisa",
    minorUnitToUnit: 100,
    symbolFirst: true,
    htmlEntity: "&#x20b9;",
    decimalMark: ".",
    thousandsSeparator: ",",
    isoNumeric: "356",
    minimumFractionDigits: 2,
  },
};

export const webCurrenciesList = [
  "EUR",
  "USD",
  "GBP",
  "AUD",
  "CAD",
  "CNY",
  "HKD",
  "JPY",
  "NZD",
  "CHF",
  "PLN",
  "INR",
] as const;

export type AllCurrencies = typeof CURRENCIES;
export type CurrenciesISO = keyof AllCurrencies;
